import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace DataWarehouseAPI {
  export class GetDataByQueryId implements APIRequest<string> {
    response: string;
    path = '/datawarehouseservice/query/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (entityId: number, public params: object) {
      this.path += entityId
    }
  }
}
